import * as React from "react"
import { useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { loadScript } from '../scripts/helpers'

import Layout from "../components/layout"
import Seo from "../components/seo"
import hero1 from '../images/volunteer/hero1.webp'
import hero2 from '../images/volunteer/hero2.webp'

const VolunteerPage = ({ location }) => {
  useEffect(() => {
      loadScript('https://static.everyaction.com/ea-actiontag/at.js');
  }, []);

  return (
    <Layout>
    <Seo location={location} title="Volunteer" />

    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-5 px-4 xl:px-0 sm:py-5">
        <div>
          <img
              className="w-full max-h-34-rem object-cover object-pos-0-87" // TODO: figure out aspect ratio -- trying to set 2:1
              src={hero1}
              alt=""
            />
        </div>

        <div className="pt-10 text-center">
          <p className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Volunteer
          </p>
        </div>

        <link rel="preload" href="https://static.everyaction.com/ea-actiontag/at.min.css" as="style" />
        <div class="ngp-form"
          data-form-url="https://secure.ngpvan.com/v1/Forms/ikD4Adf7gEO4cdKKqrLl8w2"
          data-fastaction-endpoint="https://fastaction.ngpvan.com"
          data-inline-errors="true"
          data-fastaction-nologin="true"
          data-databag-endpoint="https://profile.ngpvan.com"
          data-databag="everybody"
          data-mobile-autofocus="false">
        </div>
      
        {/* TODO: try left col. */}
        <div>
          <img
              className="w-full" // TODO: figure out aspect ratio -- trying to set 2:1
              src={hero2}
              alt=""
            />
        </div>
      </div>
    </div>

    <div className="relative bg-gray-50 pb-10 px-4 sm:px-6 lg:pb-10 lg:px-8">  
      <div className="relative max-w-7xl mx-auto">

      </div>
    </div>
  </Layout>
  )
}

export default VolunteerPage
