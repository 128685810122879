export const loadScript = (url, callback) => {
    let script = document.createElement('script'),
        scriptTag = document.querySelector(`script[src="${url}"]`);

    //remove old script before appending new one
    if (scriptTag) {
        scriptTag.parentElement.removeChild(scriptTag);
    }

    if (script.readyState){
        //IE
        script.onreadystatechange = function() {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
                if (callback) {
                    callback();
                }

            }
        };
    } else {
        //Others
        script.onload = function() {
            if (callback) {
                callback();
            }
        };
    }

    script.src = url;

    document.getElementsByTagName('head')[0].appendChild(script);
}
